import { impression, sendToGTM, sendToGTM4 } from '@lib/gtag';

export const menuEvents = {
  impression: () => impression('menu'),

  clickLogin: () =>
    sendToGTM({
      event: 'menu',
      eventAction: 'click',
      eventLabel: 'login',
      type: 'click',
      action: 'login',
    }),

  clickLogout: () =>
    sendToGTM({
      event: 'menu',
      eventAction: 'click',
      eventLabel: 'logout',
      type: 'click',
      action: 'logout',
    }),

  clickAdNew: () =>
    sendToGTM({
      event: 'menu',
      eventAction: 'click',
      eventLabel: 'ad_new',
      type: 'click',
      action: 'ad_new',
    }),

  downloadOutdatedBrowserAppear: () =>
    sendToGTM4({
      event: 'menu',
      eventAction: 'appear',
      eventLabel: 'download_outdated_browser',
      type: 'appear',
      action: 'download_outdated_browser',
    }),

  clickDownloadOutdatedBrowser: () =>
    sendToGTM4({
      event: 'menu',
      eventAction: 'click',
      eventLabel: 'download_outdated_browser',
      type: 'click',
      action: 'download_outdated_browser',
    }),

  clickIgnoreOutdatedBrowser: () =>
    sendToGTM4({
      event: 'menu',
      eventAction: 'click',
      eventLabel: 'ignore_outdated_browser',
      type: 'click',
      action: 'ignore_outdated_browser',
    }),
};
