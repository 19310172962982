import React from 'react';

import { Container } from '../../responsive/Grid';
import Footer from '../Footer/Footer';
import Main from '../../responsive/Main/Main';
import HeaderSearch from '../Header/HeaderSearch';

import style from './ErrorLayout.module.scss';

const ErrorLayout = ({ children }) => {
  return (
    // @ts-ignore temp during migration
    <Container fluid className={style.height}>
      <HeaderSearch />
      <div className={style.content}>
        <div className={style.container}>
          <Main>{children}</Main>
        </div>
      </div>
      <Footer />
    </Container>
  );
};

export default ErrorLayout;
